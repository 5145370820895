<script>
export default {
	onLaunch: function () {},
	onShow: function () {},
	onHide: function () {}
};
</script>

<style lang="scss">
/*每个页面公共css */
@import '@/uni_modules/uv-ui-tools/index.scss';
@import '@/static/index.css';

.empty {
	display: flex;
	justify-content: center;
	padding: 120rpx 0 20rpx 0;

	.image {
		width: 300rpx;
		height: 300rpx;
		display: block;
	}
}
.beian {
	width: 100%;
	padding: 60rpx 30rpx 20rpx;
	color: #000;
	font-size: 30rpx;
	text-align: center;
}
</style>
